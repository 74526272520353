import { Project } from "./pictureValidator";
import _dropdownMenu from "../_dropdownMenu";
export var ProjectHandleComponent = {
    props: {
        project: Project,
        availableProjects: Array,
        editable: Boolean
    },
    data: function () {
        return {
            isEditMode: false,
            ddInitialized: false
        };
    },
    computed: {
        idProjectWithCombinedHandle: function () {
            if (!this.project.idProject || !this.project.handle)
                return "pas encore défini";
            return this.project.idProject + ': ' + this.project.handle;
        }
    },
    watch: {
        availableProjects: function (newData, oldData) {
            this.initializeDropdown();
        }
    },
    template: "\n        <span class='handle'\n            @click='switchToEditMode'>\n            <span v-if='!isEditMode' class='project-handle'>{{idProjectWithCombinedHandle}}</span>\n            <input\n                type='text'\n                v-show='isEditMode'\n                @blur='isEditMode=false'\n                ref='input'>\n        </span>",
    methods: {
        switchToEditMode: function () {
            var _this = this;
            if (!this.editable)
                return;
            this.isEditMode = true;
            setTimeout(function () {
                _this.$refs.input.focus();
            }, 10);
        },
        initializeDropdown: function () {
            var _this = this;
            if (!this.availableProjects.length || this.ddInitialized)
                return;
            _dropdownMenu({
                entrySearchInput: this.$refs.input,
                availableEntries: this.availableProjects,
                nameOfIdField: 'idProject',
                nameOfValueField: 'handle',
                placeResultElAfter: null
            });
            this.$refs.input.addEventListener('entrySelected', function (e) {
                var selectedProject = e.detail.selectedEntry;
                _this.$emit('on-id-project-updated', selectedProject);
            });
            this.ddInitialized = true;
        }
    },
    mounted: function () {
        this.initializeDropdown();
    }
};
